import { Badge } from "@/components/ui/badge";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { Command as CommandPrimitive } from "cmdk";
import { X } from "lucide-react";
import {
	type FocusEventHandler,
	type KeyboardEvent,
	type ReactNode,
	type Ref,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";

export interface Option {
	value: string;
	label: string;
	disable?: boolean;
	/** fixed option that can't be removed. */
	fixed?: boolean;
	/** Group the options by providing key. */
	[key: string]: string | boolean | undefined;
}
interface GroupOption {
	[key: string]: Option[];
}

interface MultipleSelectorProps {
	value?: Option[];
	options?: Option[];
	placeholder?: string;
	/** Loading component. */
	loadingIndicator?: ReactNode;
	/** Empty component. */
	emptyIndicator?: ReactNode;
	/** Debounce time for async search. Only work with `onSearch`. */
	delay?: number;
	/** async search */
	onSearch?: (value: string) => Promise<Option[]>;
	onChange?: (options: Option[]) => void;
	onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
	onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
	/** Limit the maximum number of selected options. */
	maxSelected?: number;
	/** When the number of selected options exceeds the limit, the onMaxSelected will be called. */
	onMaxSelected?: (maxLimit: number) => void;
	/** Hide the placeholder when there are options selected. */
	hidePlaceholderWhenSelected?: boolean;
	disabled?: boolean;
	/** Group the options base on provided key. */
	groupBy?: string;
	className?: string;
	badgeClassName?: string;
	/**
	 * First item selected is a default behavior by cmdk. That is why the default is true.
	 * This is a workaround solution by add a dummy item.
	 *
	 * @reference: https://github.com/pacocoursey/cmdk/issues/171
	 */
	selectFirstItem?: boolean;
	/** Allow user to create option when there is no option matched. */
	creatable?: boolean;
	/** Limit the input text length. */
	maxTextLength?: number;
}

export interface MultipleSelectorRef {
	selectedValue: Option[];
	input: HTMLInputElement;
}

export function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}

function transToGroupOption(options: Option[], groupBy?: string) {
	if (options.length === 0) {
		return {};
	}
	if (!groupBy) {
		return {
			"": options,
		};
	}

	const groupOption: GroupOption = {};
	for (const option of options) {
		const key = (option[groupBy] as string) || "";
		if (!groupOption[key]) {
			groupOption[key] = [];
		}
		groupOption[key].push(option);
	}
	return groupOption;
}

function removePickedOption(groupOption: GroupOption, picked: Option[]) {
	const cloneOption = JSON.parse(JSON.stringify(groupOption)) as GroupOption;

	for (const [key, value] of Object.entries(cloneOption)) {
		cloneOption[key] = value.filter(
			(val) => !picked.find((p) => p.value === val.value),
		);
	}
	return cloneOption;
}

const MultipleSelector = forwardRef<MultipleSelectorRef, MultipleSelectorProps>(
	(
		{
			value,
			onChange,
			placeholder,
			options: arrayOptions = [],
			delay,
			onSearch,
			onBlur,
			onFocus,
			loadingIndicator,
			emptyIndicator,
			maxSelected = Number.MAX_SAFE_INTEGER,
			onMaxSelected,
			hidePlaceholderWhenSelected,
			disabled,
			groupBy,
			className,
			badgeClassName,
			selectFirstItem = true,
			creatable = false,
			maxTextLength = Number.MAX_SAFE_INTEGER,
		}: MultipleSelectorProps,
		ref: Ref<MultipleSelectorRef>,
	) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const [open, setOpen] = useState(false);
		const [isLoading, setIsLoading] = useState(false);

		const [selected, setSelected] = useState<Option[]>(value || []);
		const [options, setOptions] = useState<GroupOption>(
			transToGroupOption(arrayOptions, groupBy),
		);

		const [inputValue, setInputValue] = useState("");
		const debouncedSearchTerm = useDebounce(inputValue, delay || 500);

		useImperativeHandle(
			ref,
			() => ({
				selectedValue: [...selected],
				input: inputRef.current as HTMLInputElement,
			}),
			[selected],
		);

		const handleUnselect = useCallback(
			(option: Option) => {
				const newOptions = selected.filter((s) => s.value !== option.value);
				setSelected(newOptions);
				onChange?.(newOptions);
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[selected],
		);

		const handleKeyDown = useCallback(
			(e: KeyboardEvent<HTMLDivElement>) => {
				const input = inputRef.current;
				if (input) {
					if (e.key === "Delete" || e.key === "Backspace") {
						if (input.value === "" && selected.length > 0) {
							handleUnselect(selected[selected.length - 1]);
						}
					}
					// This is not a default behaviour of the <input /> field
					if (e.key === "Escape") {
						input.blur();
					}
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[selected],
		);

		useEffect(() => {
			if (value) {
				setSelected(value);
			}
		}, [value]);

		useEffect(() => {
			const exec = async () => {
				if (!debouncedSearchTerm || !onSearch) return;
				setIsLoading(true);
				const res = await onSearch?.(debouncedSearchTerm);
				setOptions(transToGroupOption(res, groupBy));
				setIsLoading(false);
			};

			void exec();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [debouncedSearchTerm]);

		const CreatableItem = () => {
			if (!creatable) return undefined;

			const Item = (
				<CommandItem
					value={inputValue}
					onSelect={(value: string) => {
						if (selected.length >= maxSelected) {
							onMaxSelected?.(selected.length);
							return;
						}
						setInputValue("");
						const newOptions = [...selected, { value, label: value }];
						setSelected(newOptions);
						onChange?.(newOptions);
					}}
				>{`Create "${inputValue}"`}</CommandItem>
			);

			// for normal creatable
			if (!onSearch && inputValue.length > 0) {
				return Item;
			}

			// for async search creatable. avoid showing creatable item before loading at first.
			if (onSearch && debouncedSearchTerm.length > 0 && !isLoading) {
				return Item;
			}

			return undefined;
		};

		const EmptyItem = () => {
			if (!emptyIndicator) return undefined;

			// for async search that showing emptyIndicator
			if (onSearch && !creatable && Object.keys(options).length === 0) {
				return (
					<CommandItem value="-" disabled>
						{emptyIndicator}
					</CommandItem>
				);
			}

			return <CommandEmpty>{emptyIndicator}</CommandEmpty>;
		};

		const selectables = useMemo<GroupOption>(
			() => removePickedOption(options, selected),
			[options, selected],
		);

		return (
			<Command
				onKeyDown={handleKeyDown}
				className="overflow-visible bg-transparent"
				shouldFilter={!onSearch} // when onSearch is provided, we don't want to filter the options.
			>
				<div
					className={cn(
						"group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
						className,
					)}
				>
					<div className="flex flex-wrap gap-1">
						{selected.map((option) => {
							return (
								<Badge
									key={option.value}
									className={cn(
										"data-[disabled]:bg-muted-foreground data-[disabled]:text-muted data-[disabled]:hover:bg-muted-foreground",
										"data-[fixed]:bg-muted-foreground data-[fixed]:text-muted data-[fixed]:hover:bg-muted-foreground",
										badgeClassName,
									)}
									data-fixed={option.fixed}
									data-disabled={disabled}
								>
									{option.label}
									<button
										type="button"
										className={cn(
											"ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2",
											(disabled || option.fixed) && "hidden",
										)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleUnselect(option);
											}
										}}
										onMouseDown={(e) => {
											e.preventDefault();
											e.stopPropagation();
										}}
										onClick={() => handleUnselect(option)}
									>
										<X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
									</button>
								</Badge>
							);
						})}
						{/* Avoid having the "Search" Icon */}
						<CommandPrimitive.Input
							ref={inputRef}
							value={inputValue}
							disabled={disabled}
							onValueChange={setInputValue}
							onBlur={(e) => {
								setOpen(false);
								onBlur?.(e);
							}}
							onFocus={(e) => {
								setOpen(true);
								onFocus?.(e);
							}}
							placeholder={
								hidePlaceholderWhenSelected && selected.length !== 0
									? ""
									: placeholder
							}
							className="ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
							maxLength={maxTextLength}
						/>
					</div>
				</div>
				<div className="relative mt-2">
					{open && (
						<CommandList className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
							{isLoading ? (
								<>{loadingIndicator}</>
							) : (
								<>
									{EmptyItem()}
									{CreatableItem()}
									{!selectFirstItem && (
										<CommandItem value="-" className="hidden" />
									)}
									{Object.entries(selectables).map(([key, dropdowns]) => (
										<CommandGroup
											key={key}
											heading={key}
											className="h-full overflow-auto"
										>
											<>
												{dropdowns.map((option) => {
													return (
														<CommandItem
															key={option.value}
															value={option.value}
															disabled={option.disable}
															onMouseDown={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
															onSelect={() => {
																if (selected.length >= maxSelected) {
																	onMaxSelected?.(selected.length);
																	return;
																}
																setInputValue("");
																const newOptions = [...selected, option];
																setSelected(newOptions);
																onChange?.(newOptions);
															}}
															className={cn(
																"cursor-pointer",
																option.disable &&
																	"cursor-default text-muted-foreground",
															)}
														>
															{option.label}
														</CommandItem>
													);
												})}
											</>
										</CommandGroup>
									))}
								</>
							)}
						</CommandList>
					)}
				</div>
			</Command>
		);
	},
);

MultipleSelector.displayName = "MultipleSelector";
export { MultipleSelector };
