import { CurrencyInput } from "@/components/currency-input.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	MultipleSelector,
	type MultipleSelectorRef,
	type Option,
} from "@/components/ui/multi-select.tsx";
import { cn } from "@/lib/utils.ts";
import {
	type FieldMetadata,
	unstable_useControl as useControl,
} from "@conform-to/react";
import { ArrowDownToLineIcon, Loader2 } from "lucide-react";
import { useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalPendingState } from "remix-utils/use-global-navigation-state";
import { z } from "zod";
import type { Input } from "./ui/input.tsx";
import { Label } from "./ui/label.tsx";
import { Switch, type SwitchProps } from "./ui/switch.tsx";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors;
	id?: string;
}) {
	const errorsToRender = errors?.filter(Boolean);
	if (!errorsToRender?.length) return null;
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map((e) => (
				<li key={e} className="text-[10px] text-destructive">
					{e}
				</li>
			))}
		</ul>
	);
}

type SubmitButtonProps = React.ComponentPropsWithRef<typeof Button>;
export function SubmitButton({
	className,
	children,
	...props
}: SubmitButtonProps) {
	const state = useGlobalPendingState();

	const { t } = useTranslation();

	return (
		<Button
			type="submit"
			variant="expandIcon"
			className={className}
			Icon={ArrowDownToLineIcon}
			iconPlacement="right"
			loading={state === "pending"}
			{...props}
		>
			{state === "pending" ? (
				<Loader2 className="animate-spin" />
			) : (
				children ?? t("save")
			)}
		</Button>
	);
}

export const zodCurrency = z
	.string()
	.trim()
	.transform((val) => Number(val.replaceAll(".", "")))
	.refine((val) => !Number.isNaN(val), {
		message: "Invalid currency",
	});

export function CurrencyField({
	labelProps,
	inputProps,
	errors,
	helperText,
	className,
}: {
	ref?: React.Ref<HTMLInputElement>;
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
	inputProps: React.ComponentPropsWithRef<typeof Input>;
	errors?: ListOfErrors;
	helperText?: string;
	className?: string;
}) {
	const fallbackId = useId();
	const id = inputProps.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;

	const { key, ...rest } = inputProps;
	return (
		<div className={cn("w-full", className)}>
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<CurrencyInput
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				key={key}
				{...rest}
			/>
			{helperText && (
				<p className="text-muted-foreground text-xs">{helperText}</p>
			)}
			{errorId ? (
				<div className="px-4 py-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	);
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements["label"];
	buttonProps: SwitchProps & {
		name: string;
		form: string;
		value?: string;
	};
	errors?: ListOfErrors;
	className?: string;
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps;
	const fallbackId = useId();
	const checkedValue = buttonProps.value ?? "on";
	const control = useControl({
		key,
		initialValue: defaultChecked ? checkedValue : undefined,
	});
	const id = buttonProps.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;

	return (
		<div className={cn("w-full", className)}>
			<div className="flex items-center gap-2">
				<Switch
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					{...checkboxProps}
					checked={control.value === checkedValue}
					onCheckedChange={(state) => {
						control.change(state.valueOf() ? checkedValue : "");
						buttonProps.onCheckedChange?.(state);
					}}
					onFocus={(event) => {
						control.focus();
						buttonProps.onFocus?.(event);
					}}
					onBlur={(event) => {
						control.blur();
						buttonProps.onBlur?.(event);
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="text-body-xs text-muted-foreground"
				/>
			</div>
			{errorId ? (
				<div className="px-4 py-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	);
}

interface MultiSelectFieldProps
	extends React.ComponentProps<typeof MultipleSelector> {
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
	error?: string;
	defaultValue?: string[];
	className?: string;
	meta: FieldMetadata<string[]>;
	errors?: ListOfErrors;
}

export function MultiSelectField({
	className,
	labelProps,
	error,
	meta,
	errors,
	...props
}: MultiSelectFieldProps) {
	const fallbackId = useId();
	const id = meta.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;

	const triggerRef = useRef<MultipleSelectorRef>(null);

	const [selected, setSelected] = useState<Option[] | undefined>(
		props.options?.filter(
			(x) =>
				meta?.initialValue?.includes(x.value) ||
				props?.defaultValue?.includes(x.value),
		) ?? [],
	);

	const control = useControl(meta);

	return (
		<div className={cn("w-full", className)}>
			{selected?.map((x) => (
				<input
					key={x.value}
					className="sr-only"
					tabIndex={-1}
					id={id}
					name={meta?.name}
					defaultValue={x.value}
					onFocus={() => triggerRef.current?.input.click()}
				/>
			))}
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<MultipleSelector
				ref={triggerRef}
				{...props}
				value={selected}
				onChange={setSelected}
				onBlur={control.blur}
				onFocus={control.focus}
			/>
			{errorId ? (
				<div className="px-4 py-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	);
}
